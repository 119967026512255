.grecaptcha-badge { visibility: hidden; }

/* dev hot refresh fix */
iframe {
    display: none !important;
}

.form-group.field.field-object {
    padding-left: 15px;
}

.ant-collapse-content-box p:last-child {
    margin-bottom: 0;
}

.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-only-child {
    padding-left: 12px !important;
}